// legacy ajax method for backward-compatibility with jQuery
export default function ajax(url, data, options) {
    var _a;
    const requestOptions = {
        method: (_a = options === null || options === void 0 ? void 0 : options.method) !== null && _a !== void 0 ? _a : 'POST',
        credentials: 'same-origin',
    };
    if ((options === null || options === void 0 ? void 0 : options.dataType) === 'json') {
        requestOptions.body = JSON.stringify(data);
        requestOptions.headers = {
            'Content-Type': 'application/json',
        };
    }
    else {
        requestOptions.body = makeFormData(data);
    }
    return fetch(url, requestOptions);
}
export function makeFormData(data) {
    if ('tagName' in data && data.tagName === 'FORM') {
        return new FormData(data);
    }
    const formData = new FormData();
    for (const name in data) {
        if (data.hasOwnProperty(name) && data[name] !== undefined) {
            formData.append(name, data[name]);
        }
    }
    return formData;
}
