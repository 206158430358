import 'micromodal';
import 'core-js/features/object/assign';
import { hidePreloader, showPreloader } from "./preloader";
import { fixInjectedInlineScripts, triggerEvent } from '../utils';
import MicroModal from "micromodal";
import ajax from "./ajax";
import $ from '../vendor/dom7';
const routed = ['callback', 'find_cheaper', 'preorder', 'subscribe_brand', 'gift_card_activation'];
export const microModalDefaults = {
    openTrigger: 'data-custom-open',
    disableScroll: false,
    disableFocus: true,
    awaitCloseAnimation: true,
    onShow: (modal) => {
        // focus elements
        const focusable = Array.from(modal.querySelectorAll([
            'input:not([disabled]):not([type="hidden"]):not([aria-hidden]):not([data-micromodal-nofocus]):not([data-micromodal-close])',
            'select:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus])',
            'textarea:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus])',
            'button:not([disabled]):not([aria-hidden]):not([data-micromodal-nofocus]):not([data-micromodal-close])',
        ].join(', ')));
        if (focusable.length) {
            const focusableEmpty = focusable.filter(element => element.value === '');
            if (focusableEmpty.length) {
                focusableEmpty[0].focus();
            }
            else {
                focusable[0].focus();
            }
        }
        triggerEvent(document, 'modal:open', { modal });
        fixModalClosing(modal);
    },
    onClose: function (modal) {
        const url = modal.dataset.redirect;
        triggerEvent(document, 'modal:close', { modal });
        if (!url) {
            return;
        }
        showPreloader();
        document.location = url;
        if (url[0] === '#') {
            document.location.reload();
        }
    }
};
/**
 * @param {HTMLDivElement} modal
 */
function fixModalClosing(modal) {
    const overlay = modal.querySelector('.modal__overlay');
    const inner = overlay.firstElementChild;
    let innerClick = false;
    inner.addEventListener('pointerdown', () => {
        innerClick = true;
    });
    inner.addEventListener('pointerup', (event) => {
        innerClick = false;
        event.stopPropagation();
    });
    overlay.addEventListener('pointerup', () => {
        if (innerClick) {
            innerClick = false;
            return;
        }
        MicroModal.close(modal.id);
    });
}
export default function initModal() {
    document.addEventListener('DOMContentLoaded', () => {
        MicroModal.init(microModalDefaults);
        // ссылки, вызывающие модальные окна
        $(document.body).on('click', '.win', function (e) {
            var _a;
            const button = this;
            const $this = $(button);
            if (button.dataset.modalId) {
                if (document.getElementById(button.dataset.modalId)) {
                    MicroModal.show(button.dataset.modalId, microModalDefaults);
                    return;
                }
            }
            const act = $this.data('act');
            e.preventDefault();
            showPreloader();
            ajax(`/ajax/win/${act}`, {
                id: (_a = $this.data('id')) !== null && _a !== void 0 ? _a : 0,
                type: $this.data('type'),
            })
                .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("/json") !== -1) {
                    return response.json();
                }
                return response.text();
            })
                .then(function (data) {
                if (typeof data === 'string') {
                    showModal(data);
                }
                else {
                    initAssets(data)
                        .then(element => {
                        if (data.html) {
                            showModal(element !== null && element !== void 0 ? element : data.html);
                        }
                        if (data.callback) {
                            if (typeof global[data.callback] === 'function') {
                                return global[data.callback](data);
                            }
                        }
                    });
                }
            });
            function showModal(html) {
                const id = showModalFromHtml(html);
                if (id) {
                    button.dataset.modalId = id;
                }
                hidePreloader();
            }
        });
        // отправка форм аяксом в модальных окнах
        $(document.body).on('submit', 'form', function (e) {
            const form = this;
            const $form = $(this);
            const $ajaxSubmit = $form.find('.ajax_submit');
            const act = $ajaxSubmit.data('act');
            let url;
            if (!($ajaxSubmit.length && act)) {
                return;
            }
            url = '/ajax/win_act/' + act + (routed.includes(act) ? '' : '.php');
            e.preventDefault();
            showPreloader();
            ajax(url, $form[0])
                .then(response => response.json())
                .then((json) => {
                if (json && json.ok) {
                    triggerEvent(form, 'ajax-form-success');
                    if (json.html) {
                        showModalFromHtml(json.html);
                    }
                    else if (json.win) {
                        if ($('#' + json.win).length) {
                            MicroModal.show(json.win, microModalDefaults);
                        }
                        else {
                            $('<a class="win" data-act="' + json.win + '"></a>').appendTo('.window').click().remove();
                        }
                    }
                    else {
                        // @ts-ignore
                        document.location.reload(true);
                        return;
                    }
                }
                else if (json && typeof json.error === 'object') {
                    highlightFormErrors(form, json.error);
                }
            })
                .finally(() => hidePreloader());
        });
        // submit forms on enter, when forms are not submittable
        $(document.body).on('keyup', '.enter', function (e) {
            if (e.keyCode === 13) {
                $(this).parents('form').find('.ajax_submit').click();
            }
        });
        // ajax submittable forms
        $(document.body).on('click', '.ajax_submit', function () {
            const $form = $(this).parents('form');
            if (this.type !== 'submit') {
                let $submit = $form.find('[type=submit]');
                if (!$submit.length) {
                    const $input = $('<input type="submit">');
                    $input.hide();
                    $submit = $input.appendTo($form);
                }
                $submit.click();
            }
        });
        $('.win[data-open="1"]').click();
        const autoOpenModal = $('.modal[data-auto-open]');
        if (autoOpenModal.length) {
            MicroModal.show(autoOpenModal.attr('id'), microModalDefaults);
        }
        // handle hash navigation
        checkHashDeeplink(document.location.hash.replace(/^#/, ''));
    });
}
let loading = 0;
function initAssets(data) {
    if (!data.assets) {
        return new Promise(resolve => resolve(null));
    }
    const existingAssets = [
        ...Array.from(document.scripts).map(el => el.src),
        ...Array.from(document.querySelectorAll('link[rel="stylesheet"]')).map(el => el.href),
    ];
    const virtual = document.createElement('div');
    virtual.innerHTML = data.html + data.assets;
    return new Promise(resolve => {
        const resolveCallback = () => {
            resolve(virtual.firstElementChild);
        };
        for (const element of virtual.querySelectorAll('script[src]')) {
            if (!existingAssets.includes(element.src)) {
                enqueueItem(element, resolveCallback);
            }
        }
        for (const element of virtual.querySelectorAll('link')) {
            if (!existingAssets.includes(element.href)) {
                enqueueItem(element, resolveCallback);
            }
        }
        if (data.html) {
            for (const element of virtual.querySelectorAll('script,style')) {
                virtual.firstElementChild.append(element);
            }
        }
        if (!loading) {
            resolveCallback();
        }
    });
}
function enqueueItem(element, resolve) {
    loading++;
    let el = document.createElement(element.tagName);
    document.body.append(el);
    el.onload = onload;
    if ('src' in el && 'src' in element) {
        if (element.type) {
            el.type = element.type;
        }
        else if ('noModule' in el) {
            el.noModule = element.attributes['nomodule'] !== undefined;
            loading--;
        }
        el.src = element.src;
    }
    else if ('href' in el && 'href' in element) {
        el.rel = element.rel;
        el.href = element.href;
    }
    element.remove();
    function onload() {
        return !--loading && resolve();
    }
}
export function highlightFormErrors(form, errors = {}) {
    const $form = $(form);
    resetFormErrors(form);
    for (const field in errors) {
        $form.find(`input[name="${field}"],textarea[name="${field}"],select[name="${field}"]`)
            .parents('.form-group').addClass('has-error')
            .find('.form-fb--invalid').text(errors[field]);
    }
}
export function resetFormErrors(form) {
    $(form).find('.has-error').removeClass('has-error');
}
export function closeExistingModal() {
    const modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    const closeButton = modalRoot.querySelector('[data-micromodal-close], .modal__close');
    if (closeButton) {
        closeButton.click();
    }
}
export function showModalFromHtml(modal) {
    const modalRoot = document.querySelector('.window');
    if (!modalRoot) {
        return;
    }
    closeExistingModal();
    if (typeof modal === 'string') {
        modalRoot.innerHTML = modal;
    }
    else {
        modalRoot.innerHTML = '';
        modalRoot.append(modal);
    }
    fixInjectedInlineScripts(modalRoot);
    const id = modalRoot.querySelector('.window>div').id;
    MicroModal.show(id, microModalDefaults);
    return id;
}
function checkHashDeeplink(hash) {
    if (!hash) {
        return;
    }
    const modal = document.querySelector(`.win[data-act="${hash}"]`);
    if (!modal) {
        return;
    }
    modal.click();
}
