export function pluralize(count, one, two, five = two, placeholder = '%%') {
    let number = count % 100;
    let declension = one;
    if (number > 4 && number <= 20) {
        declension = five;
    }
    else {
        number = number % 10;
        if (number >= 5 || number === 0) {
            declension = five;
        }
        else if (number >= 2 && number <= 4) {
            declension = two;
        }
    }
    if (declension.match(placeholder)) {
        return declension.replace(placeholder, count.toString());
    }
    return declension;
}
export function format(number) {
    number = number.toString();
    if (number.match(/\d{4}/)) {
        return format(number.replace(/(\d)(\d{3})\b/, '$1 $2'));
    }
    return number;
}
export function addLiveEventListener(element, events, elementSelector, callback) {
    events.split(' ').forEach(eventType => {
        element.addEventListener(eventType, function (event) {
            if (event.target instanceof HTMLElement && event.target.matches(elementSelector)) {
                return callback.call(event.target, event);
            }
        });
    });
}
/**
 * This allows us to dispatch browser events in old IE and newer browsers
 */
export function triggerEvent(elem, eventName, data = null) {
    const event = data ? new CustomEvent(eventName, { detail: data }) : new CustomEvent(eventName);
    elem.dispatchEvent(event);
    return event;
}
export function triggerProductsLoaded() {
    triggerEvent(document, 'products_loaded');
}
export function join(arr, separator = ' ') {
    return arr.filter(n => n).join(separator);
}
export function escapeRegex(string) {
    return string.replace(/[\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
export const isMobile = window.matchMedia('(pointer: coarse)').matches && window.innerWidth <= 768 && 'ontouchstart' in window;
export function getFetchOptions(body, credentials = false, method = 'POST') {
    return {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        credentials: credentials ? 'same-origin' : 'omit',
    };
}
export function fixInjectedInlineScripts(element) {
    for (const script of element.querySelectorAll('script')) {
        const newScript = document.createElement('script');
        newScript.text = script.innerHTML;
        script.parentElement.append(newScript);
        script.remove();
    }
}
export function isHidden(el) {
    return (el.offsetParent === null);
}
export function onModalOpen(modalId, callback) {
    document.addEventListener('modal:open', function (event) {
        const modal = event.detail.modal;
        if (!modal || modal.id !== modalId) {
            return;
        }
        callback(modal);
    });
}
export function emulateReadonly(element) {
    element.style.caretColor = 'transparent';
    element.addEventListener('keydown', onkeypress);
    element.addEventListener('paste', onkeypress);
    function onkeypress(event) {
        if (event.key.length === 1) {
            event.preventDefault();
        }
    }
}
export function clearDataset(el) {
    Object.keys(el.dataset).forEach(key => delete el.dataset[key]);
}
export function extractData(el) {
    const dataEl = el.querySelector('script[type="application/json"]');
    if (!dataEl) {
        return null;
    }
    return JSON.parse(dataEl.textContent);
}
